import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiFillInstagram,
  AiOutlineWhatsApp,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="textgradient"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
            Ever since I discovered programming, I've been captivated by its potential to shape the digital world. While I'm still on the learning journey, every step I take opens new doors of knowledge and possibilities. 
            &nbsp;
            <span className="rocket" role="img" aria-labelledby="rocket">
                  🚀
                </span>
              <br />
              <br />I am fluent in languages like
              <i>
                <b className="textgradient"> HTML , CSS and  Javascript . </b>
              </i>
              <br />
              <br />
              My field of Interest's are building new &nbsp;
              <i>
                <b className="textgradient">Web Technologies and Products </b> and
                also in areas related to{" "}
                <b className="textgradient">
                  AI and Blockchain.
                </b>
              </i>
              <br />
              <br />
              In the realm of web development, I find my playground. I love bringing ideas to life using  <b className="textgradient">Node.js</b> and
              <i>
                <b className="textgradient">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="textgradient"> React.js and Next.js</b>
              </i>
              &nbsp; With these tools in hand, I'm dedicated to weaving seamless, engaging, and user-centric digital experiences.
              <br/>
              <br/>
              As I continue to explore and expand my skills, my goal remains the same 
              <i>
                <b className="textgradient"> to contribute to the evolution </b>
              </i>
              &nbsp;
              of the digital landscape one code snippet at a time.
              <br/>
              <br/>
              Feel free to 
              <i>
                <b className="textgradient"> explore my work and journey.  </b>
              </i>
              <br/>
              Let's connect, collaborate, and create wonders in the world of technology.
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="textgradient">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/jayrajpurohit-23"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://wa.me/917977874412"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineWhatsApp />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://in.linkedin.com/in/jay-rajpurohit-page/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/its_jay_rajpurohit_23/?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
