import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="textgradient">Jay Rajpurohit </span>
            from <span className="textgradient"> Jodhpur, India.</span>
            <br/>
            <br /> I am a 2<sup>nd</sup> year student pursuing an Bachlors of Computer Applications (BCA)
             at Lachoo Memorial College of Science and Technology Jodhpur.
            <br />
            <br/>
            Beyond academics, I'm also actively engaged as a software developer at JRP Soft Tech, where I have the exciting opportunity to turn concepts into functional realities.
            <br />
            <br />
            But that's not all! When I'm not immersed in lines of code, you can find me indulging in a few other passions:
          </p>
          <br/>
          <ul>
            <li className="about-activity">
              <ImPointRight /><span className="textgradient"> Making Websites:</span> Crafting digital landscapes is my canvas for creativity. From user interfaces to user experiences, I thrive on bringing websites to life.
            </li>
            <br/>
            <li className="about-activity">
              <ImPointRight /><span className="textgradient"> Making Apps:</span> The world in the palm of your hand – that's what I strive to create through app development. Turning ideas into tangible applications is my way of adding value to the digital era.
            </li>
            <br/>
            <li className="about-activity">
              <ImPointRight /><span className="textgradient"> Travelling:</span> Just as I navigate the virtual world of programming, I also love exploring the physical world. Traveling is my way of collecting inspiration and widening my horizons.
            </li>
          </ul>
          

          <p style={{ color: "rgb(155 126 172)" }}>
          "Apps and websites aren't just lines of code; they're the bridges connecting ideas to users."{" "}
          </p>
          <footer className="blockquote-footer">Jay Rajpurohit</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
