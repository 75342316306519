import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import jrpsofttech from "../../Assets/Projects/JRPSOFTTECH.png";
import hslawfirm from "../../Assets/Projects/HSLAWFIRM.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="textgradient">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={jrpsofttech}
              isBlog={false}
              title="JRP Soft Tech"
              description="The JRP Soft Tech website is more than a virtual space; it's an embodiment of our passion for technology and innovation. It invites visitors to explore our journey, services, and values, fostering connections and opportunities.
              
              As a full-stack website developer, I take immense pride in delivering a platform that encapsulates the essence of JRP Soft Tech and amplifies our digital footprint.
              
              Thank you for exploring this project. If you're seeking innovative solutions, let's connect and bring your vision to life."
              ghLink="https://github.com/jayrajpurohit-23"
              ViewLink="https://www.jrpsofttech.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hslawfirm}
              isBlog={false}
              title="HS-Law-Firm"
              description="The HS Law Firm website isn't merely an online presence; it's a representation of a modern legal practice poised for success in the digital age. By seamlessly merging design, functionality, and legal expertise, we've created a platform that extends the firm's reach and resonates with its audience.

              As a passionate developer, I'm immensely proud of contributing to a project that showcases how technology can amplify the legal profession's impact.
              
              Thank you for exploring this project. If you're ready to elevate your digital presence, let's connect and explore how we can craft a dynamic online space for you."
              ghLink="https://github.com/jayrajpurohit-23"
              ViewLink="https://www.hanumansinghrajpurohit.com/"          
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={jrpsofttech}
              isBlog={false}
              title="JRP Soft Tech"
              description="The JRP Soft Tech website is more than a virtual space; it's an embodiment of our passion for technology and innovation. It invites visitors to explore our journey, services, and values, fostering connections and opportunities.
              
              As a full-stack website developer, I take immense pride in delivering a platform that encapsulates the essence of JRP Soft Tech and amplifies our digital footprint.
              
              Thank you for exploring this project. If you're seeking innovative solutions, let's connect and bring your vision to life."
              ghLink="https://github.com/jayrajpurohit-23"
              ViewLink="https://www.jrpsofttech.com/"              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={jrpsofttech}
              isBlog={false}
              title="JRP Soft Tech"
              description="The JRP Soft Tech website is more than a virtual space; it's an embodiment of our passion for technology and innovation. It invites visitors to explore our journey, services, and values, fostering connections and opportunities.
              
              As a full-stack website developer, I take immense pride in delivering a platform that encapsulates the essence of JRP Soft Tech and amplifies our digital footprint.
              
              Thank you for exploring this project. If you're seeking innovative solutions, let's connect and bring your vision to life."
              ghLink="https://github.com/jayrajpurohit-23"
              ViewLink="https://www.jrpsofttech.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={jrpsofttech}
              isBlog={false}
              title="JRP Soft Tech"
              description="The JRP Soft Tech website is more than a virtual space; it's an embodiment of our passion for technology and innovation. It invites visitors to explore our journey, services, and values, fostering connections and opportunities.
              
              As a full-stack website developer, I take immense pride in delivering a platform that encapsulates the essence of JRP Soft Tech and amplifies our digital footprint.
              
              Thank you for exploring this project. If you're seeking innovative solutions, let's connect and bring your vision to life."
              ghLink="https://github.com/jayrajpurohit-23"
              ViewLink="https://www.jrpsofttech.com/" 
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={jrpsofttech}
              isBlog={false}
              title="JRP Soft Tech"
              description="The JRP Soft Tech website is more than a virtual space; it's an embodiment of our passion for technology and innovation. It invites visitors to explore our journey, services, and values, fostering connections and opportunities.
              
              As a full-stack website developer, I take immense pride in delivering a platform that encapsulates the essence of JRP Soft Tech and amplifies our digital footprint.
              
              Thank you for exploring this project. If you're seeking innovative solutions, let's connect and bring your vision to life."
              ghLink="https://github.com/jayrajpurohit-23"
              ViewLink="https://www.jrpsofttech.com/shop"  
            />
          </Col>
        </Row> 
      </Container>
    </Container>
  );
}

export default Projects;
